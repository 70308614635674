.content {
  color: #fff;

  .title {
    font-family: CrimsonText-Italic;
    font-weight: normal;
    font-size: 54px;
    line-height: 1.1;
    color: #68f3f6;
    font-style: normal;
    text-transform: none;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .desc {
    margin-top: 28px;
    font-family: Inter, Inter;
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
    letter-spacing: 1px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}

